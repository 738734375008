<template>
  <v-container>
    <v-btn
      elevation="2"
      fab
      absolute
      small
      @click="$router.go(-1)"
      v-show="!$vuetify.breakpoint.smAndDown"
    ><v-icon color="primary">mdi-arrow-left-bold</v-icon></v-btn>

    <v-row dense>
      <v-col align="center">
        <span class="text-h4">
          {{ category.name }}
        </span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col align="center">
        <span class="text-subtitle">
          {{ category.sub_name }}
        </span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col align="center">
        <span class="text-h6">Ďalšie súvisiace tézy (tvrdenia) k hlavnej otázke: <span class="primary--text font-weight-medium">{{ question.first_field }}</span></span>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        align="left"
      >
        <v-list rounded>
          <v-list-item-group color="primary">

            <v-list-item
              v-for="subQuestion in question.sub_questions"
              v-bind:key="subQuestion.id"
              :to="'/poll_question/'+subQuestion.id+'/'"
            >
              <v-list-item-content class="primary--text text-button">
                {{ subQuestion.first_field }}
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

        </v-list>
      </v-col>
    </v-row>

  </v-container>
</template>
      
      <script>
import { mapGetters } from "vuex";

import { APIService } from "@/http/APIService.js";


const apiService = new APIService();


export default {
  name: 'Poll',
  inheritAttrs: true,

  components: {
  },

  props: {
  },

  data() {
    return {
      question: {},
      category: {},
      description_show: undefined,
      technical_info_show: undefined,
    }
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedBank",
      "isVerifiedSmsEmail",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender",
      "user_id"
    ]),

    ...mapGetters("settings", ["getSettings"]),

    questionID() { return parseInt(this.$route.params.questionID) },
    categoryID() { return parseInt(this.$route.params.categoryID) },
  },


  events: {},

  watch: {
  },

  mounted: function () {
    this.getCategory();
    this.getQuestion();
  },

  methods: {
    getCategory() {
      apiService.getGeneralPollCategory(this.categoryID).then(response => {
        this.category = response;
      });
    },

    getQuestion() {
      apiService.getGeneralPollQuestion(this.questionID).then(response => {
        this.question = response;
      });
    }
  },
};
      </script>